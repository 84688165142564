import React, {useState} from 'react';
import MyInput from "./UI/input/MyInput";
import MyButton from "./UI/button/MyButton";

const LoginPage = ({errors, onLoginSuccess, domain, port}) => {
    const [value, setValue] = useState({login: '', pass: ''});
    async function sendDataToServer(userData) {
        // console.log(userData);
        let pResult = await fetch(`https://${domain}/api/auth`, {
            method: 'POST',
            headers: {
                "Content-Type" : 'application/json'
            },
            body: JSON.stringify(userData),
            credentials: "include"
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error with fetch: ', response.statusText);
                }
                return response.json();
            })
            .then(data => {
                console.log("Success: ", data);
                localStorage.setItem("token", data.token)
                errors("");
                onLoginSuccess();
                return "success"
            })
            .catch(error => {
                console.error(error);
                errors("Error with login or password. Try again!")
                return "error"
            })
        return pResult;
    }

    const makeData = async (e) => {
        e.preventDefault();
        const userData = {
            ...value, id: Date.now()
        }
        let result = await sendDataToServer(userData);
        if (result === "success") {
            setValue({login: '', pass: ''})
        }
    }

    return (
        <div className="loginPage">
            <div className="loginText">
                Войдите в аккаунт
            </div>
            <MyInput
                value={value.login}
                onChange={e => setValue({...value, login: e.target.value})}
                type="email"
                placeholder="Логин"
            />
            <MyInput
                value={value.pass}
                onChange={e => setValue({...value, pass: e.target.value})}
                type="password"
                placeholder="Пароль"
            />
            <div className="loginButton">
                <MyButton
                    onClick = {makeData}
                >
                    Войти в аккаунт
                </MyButton>
            </div>
        </div>
    );
};

export default LoginPage;