import React, {useEffect, useMemo, useRef, useState} from "react";
import Cookies from 'js-cookie';
import MyButton from "./components/UI/button/MyButton";
import MyInput from "./components/UI/input/MyInput";
import "../src/styles/App.css"

import LoginPage from "./components/LoginPage";
import StatisticsTable from "./components/StatisticTable";
import AdminPanel from "./components/AdminPanel";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isGotoAdmin, setIsGotoAdmin] = useState(false);

    const port = 3000;
  // const domain = "45.144.30.10" :${port}
    const domain = "hotfarmbot.online"


  useEffect( () => {
      setIsError("")
      setIsAdministrator(false);
      setIsGotoAdmin(false);
      const cookieValue = localStorage.getItem("token")
      console.log(cookieValue)

      if (cookieValue) {
          fetch(`https://${domain}/api/authorization`, {
              method: "POST",
              headers: {
                  'Content-Type' : 'application/json'
              },
              body: JSON.stringify({token : cookieValue})
          })
              .then(response => {
                  if (!response.ok) {
                      throw new Error('Error with fetch: ', response.statusText)
                  }
                  return response.json()
              })
              .then(data => {
                  console.log("Success: ", data);
                  if (data.key === "authPassed") {
                      setIsAuthenticated(true);
                      localStorage.setItem("role", data.role);
                      if (data.role === "administrator") {
                          setIsAdministrator(true);
                      }
                      console.log(isAuthenticated)
                      setIsError("")
                  } else {
                      setIsAuthenticated(false);
                      localStorage.removeItem("token");
                      localStorage.removeItem("role");
                      setIsError("Authefication not passed. Re-login")
                  }

              })
              .catch(error => {
                  console.error(error);
                  setIsAuthenticated(false);
                  localStorage.removeItem("token");
                  localStorage.removeItem("role");
                  setIsError("Authefication not passed. Re-login");
              })

      } else {
          setIsAuthenticated(false);
      }
  }, [trigger]);
    const setError = (text) => {
        setIsError(text)
    }
    const setSuccess = (text) => {
        setIsSuccess(text)
    }

    const handleLoginSuccess = () => {
        setTrigger(prev => !prev);
    }

    function logOut() {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        setTrigger(prev => !prev);
    }

    const renderContent = () => {
        if (!isAuthenticated) {
            return <LoginPage errors={setError} onLoginSuccess={handleLoginSuccess} domain={domain} port={port}/>;
        }

        if (isGotoAdmin) {
            return <AdminPanel errors={setError} successes={setSuccess} domain={domain} port={port} />;
        }

        return <StatisticsTable domain={domain} port={port} />;
    }
    const renderAdmin = () => {
        if (isAdministrator) {
            if (isGotoAdmin) {
                return <MyButton
                    style={{padding: "5px 10px" , margin: "0px 10px"}}
                    onClick={() => setIsGotoAdmin(false)}
                >Statistics</MyButton>
            } else {
                return <MyButton
                    style={{padding: "5px 10px" , margin: "0px 10px"}}
                    onClick={() => setIsGotoAdmin(true)}
                >Admin</MyButton>
            }
        } else {
            return null;
        }
    }



    return (
    <div className="App">
        <header className="headerPart">
            <div className="headerName" id="headerElem">
                HOT FARM BOT
            </div>
            <div className="headerBtns" style={{fontSize: "14px"}}>
                {renderAdmin()}


                {isAuthenticated ? (
                    <MyButton
                        style={{padding: "5px 10px", margin: "0px 10px"}}
                        onClick={logOut}
                    >Logout</MyButton>
                ) : <div></div>
                }


            </div>
        </header>
        <div className="errorField">
            {isError}
        </div>
        <div className="successField">
            {isSuccess}
        </div>
        {/*{isAuthenticated ?*/}
        {/*    isGotoAdmin ? (<div></div>) : (<StatisticsTable/>)*/}


        {/* : (*/}
        {/*    <LoginPage errors={setError} onLoginSuccess={handleLoginSuccess}/>)*/}
        {/*}*/}
        {renderContent()}

    </div>
  );
}

export default App;
