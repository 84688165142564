import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form'
import {Container, Table, Row, Col} from 'react-bootstrap'
import Modal from "./UI/modal/MyModal"
import MyButton from "./UI/button/MyButton";
import {responsivePropType} from "react-bootstrap/createUtilityClasses";
import MyModal from "./UI/modal/MyModal";
import MyInput from "./UI/input/MyInput";
import EditFields from "./EditFields";
import {getElementError, logDOM} from "@testing-library/react";
import {getElement} from "bootstrap/js/src/util";
import "../components/componentStyles/AdminPanel.css"
const AdminPanel = ({errors,successes,domain, port}) => {
    const [isToggled, setIsToggled] = useState(false);
    const [serverStatus, setServerStatus] = useState(false)
    const [botStatus, setBotStatus] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [userData, setUserData] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [modalData, setModalData] = useState({title: `Сохранение нового пользователя`, body: `Вы уверены, что хотите сохранить данные пользователя?`})
    const [newUser, setNewUser] = useState(true);
    const [idToChange, setIdToChange] = useState(-1);
    const [changedUserData, setChangedUserData] = useState([]);
    const [selectedCol, setSelectedCol] = useState('Управление');

    useEffect(() => {
        setServerStatus(false);
        setBotStatus(false);
        refreshData("refreshStatus");
        // Проверяем состояние и соединяем с кнопкой
        if (botStatus) {
            const elem = document.getElementById("isToggled");
            console.log(elem);
            elem.checked = true;
        }


    }, [trigger]);

    // const forms = document.getElementById("formToCheck");
    // (() => {
    //     'use strict'
    //
    //     // Fetch all the forms we want to apply custom Bootstrap validation styles to
    //
    //
    //     // Loop over them and prevent submission
    //     Array.from(forms).forEach(form => {
    //         form.addEventListener('submit', event => {
    //             if (!form.checkValidity()) {
    //                 event.preventDefault()
    //                 event.stopPropagation()
    //             }
    //
    //             form.classList.add('was-validated')
    //         }, false)
    //     })
    // })()




    function delay(time) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve("this lap is done");
            }, time)
        })
    }
    const toggleBot = async () => {
        console.log('Toggled')
        if (botStatus) {
            const elem = document.getElementById("isToggled");
            elem.disabled = true;
            let result = await fetchServer("stopBot");
            if (result === "serverIsDown") {
                await refreshData();
                await delay(5000);
                elem.disabled = false;
                return;
            } else {
                await delay(5000);
                elem.disabled = false;
                console.log(result);
                switch (result.key) {
                    case 'botNotStarted':
                        setServerStatus(true);
                        setBotStatus(false);
                        // console.log("Bot wasn't start yet.")
                        errors("Bot wasn't start yet.");
                        await delay(3000);
                        errors(" ");
                        break;
                    case 'botStopping':
                        setServerStatus(true);
                        setBotStatus(false);
                        // console.log("Bot will stop after last iteration.");
                        successes("Bot will stop after last iteration.");
                        await delay(3000);
                        successes(" ");
                        break;
                    case 'errorRequest':
                        setServerStatus(true);
                        setBotStatus(false);
                        // console.log("Something went wrong...");
                        errors("Something went wrong...");
                        await delay(3000);
                        errors(" ");
                        break;
                    default:
                        setServerStatus(false);
                        setBotStatus(false);
                        // console.log("Undefined key. Refresh manually");
                        errors("Undefined key. Refresh manually");
                        await delay(3000);
                        errors(" ");
                        break;
                }
            }
        } else {
            const elem = document.getElementById("isToggled");
            elem.disabled = true;
            let result = await fetchServer("startBot");
            if (result === "serverIsDown") {
                await refreshData();
                await delay(5000);
                elem.disabled = false;
                return;
            } else {
                await delay(5000);
                elem.disabled = false;
                console.log(result);
                switch (result.key) {
                    case 'botStarting':
                        setServerStatus(true);
                        setBotStatus(true);
                        successes("Bot started!");
                        await delay(3000);
                        successes(" ");
                        break;
                    case 'botAlreadyStarted':
                        setServerStatus(true);
                        setBotStatus(true);
                        // console.log();
                        errors("Bot already started!");
                        await delay(3000);
                        errors(" ");
                        break;
                    case 'botStartError':
                        setServerStatus(true);
                        setBotStatus(false);
                        // console.log();
                        errors("Error with starting");
                        await delay(3000);
                        errors(" ");
                        break;
                    case 'errorRequest':
                        setServerStatus(true);
                        setBotStatus(false);
                        // console.log();
                        errors("Something went wrong...");
                        await delay(3000);
                        errors(" ");
                        break;
                    default:
                        setServerStatus(false);
                        setBotStatus(false);
                        // console.log();
                        errors("Undefined key. Refresh manually");
                        await delay(3000);
                        errors(" ");
                        break;
                }


            }
        }
    }
    async function fetchDataServer (key) {
        var obj = {};
        console.log(changedUserData);
        if (key === "saveUserData") {
            obj = {
                token: localStorage.getItem("token"),
                key: key,
                bodyData: changedUserData
            };
        } else {
            obj = {
                token: localStorage.getItem("token"),
                key:key
            };
        }
        console.log(obj);
        let dResult = await fetch(`https://${domain}/api/dataOperations`, {
            method: "POST",
            headers: {
                "Content-Type" : "application/json"
            },
            body: JSON.stringify(obj)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Error fetching: ", response.statusText);
                } else {
                    return response.json();
                }
            })
            .then(data => {
                console.log("Success: ", data);
                return data;
            })
            .catch(e => {
                return 'serverIsDown';
            })
        console.log(dResult);
        return dResult;
    }
    const fetchServer = async (key) => {
        let obj = {
            token: localStorage.getItem("token"),
            key: key
        }
        console.log("refreshed")
        let pResult = await fetch(`https://${domain}/api/status`, {
            method: "POST",
            headers: {
                'Content-Type' : 'application/json'
            },
            body: JSON.stringify(obj)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Error with fetching: ", response.statusText)
                } else {
                    return response.json();
                }
            })
            .then(data => {
                // console.log("Success: ", data);
                return data;
            })
            .catch(e => {
                // console.error(e);
                return "serverIsDown";
            })
        return pResult;
    }
    const refreshAll = async () => {
        let elem = document.getElementById("refreshButton")
        elem.disabled = true;
        let result = await fetchServer("refreshAll");
        console.log("Refresh message: ", result.message);
        if (result.key === "refreshError") {
            console.log(result)
            errors(result.message);
            elem.disabled = false;
        } else if (result.key === "refreshSuccess") {
            console.log(result)
            errors(" ");
            successes(result.message);
            await delay(15000);
            successes(" ");
            elem.disabled = false;
        }
    }
    const refreshData = async () => {
        let result = await fetchServer("refreshStatus");
        if (result === "serverIsDown") {
            console.log('Сервер отключен')
            setServerStatus(false);
            setBotStatus(false);
        } else {
            if (result.botStatus) {
                setServerStatus(true);
                setBotStatus(true);
            } else {
                setServerStatus(true);
                setBotStatus(false);
            }
        }
    }
    function pasteData(data, ID) {
        var label = document.getElementById("labelMain");
        var number = document.getElementById("number");
        var owner = document.getElementById("owner");
        var seeds = document.getElementById("seeds");
        var deviceid = document.getElementById("deviceid");
        var loginKey = document.getElementById("loginKey");
        var loginValue = document.getElementById("loginValue");
        var storageKey = document.getElementById("storageKey");
        var storageValue = document.getElementById("storageValue");
        var telegram = document.getElementById("telegram__initParams");
        var address = document.getElementById("address");
        var port = document.getElementById("port");
        var logins = document.getElementById("login");
        var pass = document.getElementById("password");
        console.log(ID)
        label.innerText = `Основная информация пользователя [${ID}]`;
        number.value = data[ID].info.number;
        owner.value = data[ID].info.owner;

        seeds.value = data[ID].LSInfo.seeds;
        deviceid.value = data[ID].LSInfo._deviceid;
        loginKey.value = data[ID].LSInfo.loginKey;
        loginValue.value = data[ID].LSInfo.loginValue;
        storageKey.value = data[ID].LSInfo.storageKey;
        storageValue.value = data[ID].LSInfo.storageValue;

        telegram.value = JSON.stringify(data[ID].SSInfo.__telegram__initParams);

        address.value = data[ID].proxy.proxyAddress;
        port.value = data[ID].proxy.proxyPort;
        logins.value = data[ID].proxy.proxyLogin;
        pass.value = data[ID].proxy.proxyPass;
    }
    function takeData() {
        var number = document.getElementById("number");
        var owner = document.getElementById("owner");
        var seeds = document.getElementById("seeds");
        var deviceid = document.getElementById("deviceid");
        var loginKey = document.getElementById("loginKey");
        var loginValue = document.getElementById("loginValue");
        var storageKey = document.getElementById("storageKey");
        var storageValue = document.getElementById("storageValue");
        var telegram = document.getElementById("telegram__initParams");
        var address = document.getElementById("address");
        var port = document.getElementById("port");
        var logins = document.getElementById("login");
        var pass = document.getElementById("password");
        let obj = {
            "number" : number.value,
            "owner" : owner.value,
            "seeds" : seeds.value,
            "deviceid" : deviceid.value,
            "loginKey" : loginKey.value,
            "loginValue" : loginValue.value,
            "storageKey" : storageKey.value,
            "storageValue" : storageValue.value,
            "telegram" : JSON.parse(telegram.value),
            "address" : address.value,
            "port" : port.value,
            "logins" : logins.value,
            "pass" : pass.value,
        }
        return obj;
    }
    function clearAllFields() {
        var label = document.getElementById("labelMain");
        var number = document.getElementById("number");
        var owner = document.getElementById("owner");
        var seeds = document.getElementById("seeds");
        var deviceid = document.getElementById("deviceid");
        var loginKey = document.getElementById("loginKey");
        var loginValue = document.getElementById("loginValue");
        var storageKey = document.getElementById("storageKey");
        var storageValue = document.getElementById("storageValue");
        var telegram = document.getElementById("telegram__initParams");
        var address = document.getElementById("address");
        var port = document.getElementById("port");
        var logins = document.getElementById("login");
        var pass = document.getElementById("password");
        label.innerText = `Добавляем пользователя`;
        number.value = "";
        owner.value = "";

        seeds.value = "";
        deviceid.value = "";
        loginKey.value = "";
        loginValue.value = "";
        storageKey.value = "";
        storageValue.value = "";

        telegram.value = "";

        address.value = "";
        port.value = "";
        logins.value = "";
        pass.value = "";
    }
    const addUserClear = () => {
        clearAllFields();
        setNewUser(true);
        setIdToChange(-1);
        setModalData({title: `Сохранение нового пользователя`, body: `Вы уверены, что хотите сохранить данные пользователя?`})
        document.getElementById("editFieldBody").scrollIntoView();
    }
    function getTime(data) {
        let timeNow = Date.now();
        let difference = Number(data) - Number(timeNow);
        // console.log(difference);
        let minutes = 0;
        let hours = 0;
        minutes = Math.floor(difference/1000/60);
        if (minutes < 0) {
            minutes = 0;
            hours = 0;
        } else {
            hours = Math.floor(minutes/60);
            minutes = minutes - hours*60;
        }
        hours = hours.toString();
        minutes = minutes.toString();

        if (hours.length < 2) {
            hours = "0"+hours;
        }
        if (minutes.length < 2) {
            minutes = "0"+minutes;
        }
        let resultString = `${hours}h ${minutes}m`



        return resultString;
    }
    const loadData = () => {
        if (userData.length < 1) {
            return <div><a
                onClick={async (e) => {
                    setUserData([]);
                    e.preventDefault();
                    let result = await fetchDataServer("getUserData");
                    setUserData(result);
                    loadData();
                    setNewUser(true);
                    setIdToChange(-1);
                    setModalData({title: `Сохранение нового пользователя`, body: `Вы уверены, что хотите сохранить данные пользователя?`})
            }}
                style={{cursor:"pointer"}}
                className="text-primary"
            >...load users data</a></div>
        } else {
            return <Table>
                <thead className="tableHeader">
                <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">Номер</th>
                    <th className="text-center">Время до клейма</th>
                    <th className="text-center"></th>
                </tr>
                </thead>
                <tbody className="tableBody">
                {userData.map(item => (
                    <tr key={item.info.ID} onClick={async (e) => {
                        const deleteBtn = e.target.closest('#deleteBtn');
                        if (!deleteBtn) {
                            setModalData({title: `Сохранение нового пользователя`, body: `Вы уверены, что хотите сохранить данные пользователя?`});
                            setNewUser(false);
                            await pasteData(userData, item.info.ID)
                            document.getElementById("editFieldBody").scrollIntoView();
                            setIdToChange(item.info.ID);
                            setModalData({title: `Сохранение пользователя ${item.info.ID}`, body: `Вы уверены, что хотите сохранить данные пользователя [${item.info.ID}]?`})

                        } else if (deleteBtn) {
                            e.stopPropagation();
                            deleteUser(item.info.ID);
                        }
                        }}>
                        <td className="text-center">{item.info.ID}</td>
                        <td className="text-center">{item.info.number}</td>
                        <td className="text-center">{getTime(item.parsedData.nextClaim)}</td>
                        <td className="text-center">
                            <svg id="deleteBtn" style={{cursor:"pointer"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-x" viewBox="0 0 16 16">
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                            </svg></td>
                    </tr>
                ))}
                </tbody>


            </Table>
        }
    }
    function changeData(data, ID) {
        let newChangedUserData = [];
        for (var key in data) {
            // Создайте новый объект, скопировав оригинальный объект с помощью JSON.parse(JSON.stringify(obj))
            var newObj = JSON.parse(JSON.stringify(data[key]));
            newChangedUserData.push(newObj);
        }
        newChangedUserData.splice(ID, 1);
        for (let i = ID; i < newChangedUserData.length; i++) {
            newChangedUserData[i].info.ID -= 1;
            newChangedUserData[i].info.ID = newChangedUserData[i].info.ID.toString();
        }
        return newChangedUserData;
    }

    function createCorrectObject(newObj, ID) {
        let formObj = {
            "info": {
                "ID": ID.toString(),
                "number": newObj.number,
                "owner" : newObj.owner
            },
            "LSInfo": {
                "seeds": newObj.seeds,
                "_deviceid": newObj.deviceid,
                "loginKey": newObj.loginKey,
                "loginValue": newObj.loginValue,
                "storageKey": newObj.storageKey,
                "storageValue": newObj.storageValue
            },
            "SSInfo": {
                "__telegram__initParams": newObj.telegram
            },
            "parsedData": {
                "lastBalance": "",
                "newBalance": "",
                "lastClaim": "",
                "nextClaim": ""
            },
            "proxy": {
                "proxyAddress": newObj.address,
                "proxyPort": newObj.port,
                "proxyLogin": newObj.logins,
                "proxyPass": newObj.pass
            }
        }
        return formObj;
    }
    function changeValues(data, ID, changesObj) {
        let newChangedUserData = [];
        for (var key in data) {
            var newObj = JSON.parse(JSON.stringify(data[key]));
            newChangedUserData.push(newObj);
        }
        if (ID === "new") {
            let newID = newChangedUserData.length;
            let resultNewObj = createCorrectObject(changesObj, newID);
            newChangedUserData.push(resultNewObj);
        } else {
            let resultObj = createCorrectObject(changesObj, ID);
            newChangedUserData[ID] = resultObj;
        }
        return newChangedUserData;
    }


    const deleteUser = (id) => {
        console.log(id);
        setModal(true);
        setModalData({title: `Пользователь ${id}`, body: `Вы уверены, что хотите удалить пользователя [${id}] ?`});
        const newChangedUserData = changeData(userData, id);
        setChangedUserData(newChangedUserData);
    }
    const activateModal = () => {
        setModal(true);
    }

    const startSubmiting = () => {
        setModal(true);
        let result = takeData();
        console.log(newUser);
        console.log(idToChange);
        if (newUser && idToChange === -1) {
            let newV = "new";
            const newChangedUserData = changeValues(userData, newV, result);
            setChangedUserData(newChangedUserData);
        } else if (!newUser && idToChange !== -1){
            const newChangedUserData = changeValues(userData, idToChange, result);
            setChangedUserData(newChangedUserData);
        } else {
            console.log("error with -1 and newuser")
        }
    }

    const saveChanges = async (changedUserData) => {
        console.log("Отправляем дату на сервер")
        await fetchDataServer("saveUserData", changedUserData);
        console.log("Отправили")
        setUserData([]);
        console.log("Обьнулили, отправляем получить")
        let result = await fetchDataServer("getUserData");
        console.log("Получили")
        setUserData(result);
        console.log("Обновили")
        console.log(userData);
    }

    return (
        <div>
            <Container>
                <Row className="menuRow">
                    <Col className={`col-item ${selectedCol === 'Управление' ? 'selectedMenu' : 'unselectedMenu'}`} onClick={() => setSelectedCol('Управление')}>Управление</Col>
                    <Col className={`col-item ${selectedCol === 'Профили' ? 'selectedMenu' : 'unselectedMenu'}`} onClick={() => setSelectedCol('Профили')}>Профили</Col>
                    <Col className={`col-item ${selectedCol === 'Пользователи' ? 'selectedMenu' : 'unselectedMenu'}`} onClick={() => setSelectedCol('Пользователи')}>Пользователи</Col>
                </Row>
            </Container>


            {selectedCol === 'Управление' && <div>
                <Container>
                    <h2 className="tableTitle" id="manageTitle">Управление ботом</h2>
                    <div className="serverPanel">
                        <div className="statusPanel">

                            <div className="statusLabel">
                                Статус сервера:
                            </div>
                            {serverStatus ? (
                                <div className="statusText text-success" id="serverStatus">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    </svg>
                                    <span className="spanNearCircle" id="serverStatus">Сервер доступен</span>
                                </div>
                            ) : (
                                <div className="statusText text-danger" id="serverStatus">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    </svg>
                                    <span className="spanNearCircle" id="serverStatus">Сервер недоступен</span>
                                </div>
                            )}

                            {botStatus ? (
                                <div className="statusText text-success" id="botStatus">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    </svg>
                                    <span className="spanNearCircle" id="serverStatus">Бот запущен</span>
                                </div>
                            ) : (
                                <div className="statusText text-danger" id="botStatus">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    </svg>
                                    <span className="spanNearCircle" id="serverStatus">Бот выключен</span>
                                </div>
                            )}

                        </div>
                        <div className="togglePanel">
                            <div className="statusLabel">
                                Включить-Выключить бота:
                            </div>
                            <div className="">
                                {botStatus ? (
                                    <Form.Check
                                        type='switch'
                                        id='isToggled'
                                        label="Бот включен"
                                        onClick={toggleBot}
                                        checked
                                    />
                                ) : (
                                    <Form.Check
                                        type='switch'
                                        id='isToggled'
                                        label="Бот выключен"
                                        onClick={toggleBot}
                                    />
                                )}

                            </div>
                            <MyButton
                                style={{display: "flex", flexDirection: 'row', padding: "5px 10px" , margin: "15px 10px"}}
                                onClick={refreshData}
                            >
                                <span style={{paddingRight: "10px"}}>Обновить статус</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                    <path
                                        d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                                    <path fill-rule="evenodd"
                                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                                </svg>
                            </MyButton>
                            <MyButton
                                style={{display: "flex", flexDirection: 'row', padding: "5px 10px" , margin: "15px 10px"}}
                                onClick={refreshAll}
                                id="refreshButton"
                            >
                                <span style={{paddingRight: "10px"}}>Обновить всех</span>
                            </MyButton>
                            <MyButton
                                style={{display: "flex", flexDirection: 'row', padding: "5px 10px" , margin: "15px 10px"}}
                                onClick={addUserClear}
                            >
                                <span style={{paddingRight: "10px"}}>Добавить пользователя</span>
                            </MyButton>
                        </div>
                    </div>
                    {/*<div className="changeProfilesPanel">*/}
                    {/*    <div className="changeProfilesLabel">*/}
                    {/*        */}
                    {/*    </div>*/}
                    {/*    <div className="changeProfilesTable">*/}
                    {/*        */}
                    {/*    </div>*/}
                    {/*</div>*/}
                </Container>
            </div>}
            {selectedCol === 'Профили' && <div>
                <Container>
                    <h2 className="tableTitle" id="changeProfilesTitle">Изменение профилей</h2>

                    {loadData()}

                    <EditFields classname={{display: "flex"}} modal={activateModal} modalData={modalData} setModalData={setModalData} startSubmiting={startSubmiting}/>

                    <MyModal visible={modal} setVisible={setModal}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalTitle">{modalData.title}</h5>
                                </div>
                                <div className="modal-body" id="modalBody">
                                    <p id="modalBodyText">{modalData.body}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary submitBtn"
                                            id="saveChangesBtn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModal(false);
                                                saveChanges();
                                            }}
                                    >Сохранить изменения
                                    </button>
                                    <button type="button" className="btn btn-secondary closeBtn" data-bs-dismiss="modal"
                                            id="closeBtn"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setModal(false);
                                            }}
                                    >Закрыть
                                    </button>
                                </div>
                            </div>
                        </div>
                    </MyModal>


                </Container>
            </div>}
            {selectedCol === 'Пользователи' && <div>Пользователи</div>}





        </div>
    );
};

export default AdminPanel;