import React, {useState} from 'react';
import cl from "./MyInput.module.css"
import Form from 'react-bootstrap/Form'
const MyInput = (props) => {
    // console.log(props)
    const [isVisible, setIsVisible] = useState(false);


    function showInput() {
        const elem = document.getElementById("passInput");
        const checkbox = document.getElementById("isVisible");
        if (!isVisible) {
            if (elem.type === "password") {
                elem.type = "text";
                setIsVisible(true);
            } else {
                checkbox.checked = false;
            }
        } else if (isVisible) {
            if (elem.type === "text") {
                elem.type = "password";
                setIsVisible(false);
            } else {
                checkbox.checked = true;
            }
        }
    }

    return (
        <div className={cl.divInput}>
            {props.type === "password" ? (
                <div className={cl.divInput}>
                    <input className={cl.myInput} id="passInput" {...props}/>
                    {/*<div className={cl.checkBox}>*/}
                    {/*    <input type="checkbox" id="isVisible" name="isVisible" onClick={showInput}/>*/}
                    {/*    <label htmlFor="isVisible">Показать пароль</label>*/}
                    {/*</div>*/}
                    <Form.Check
                        type="switch"
                        id="isVisible"
                        label="Показать пароль"
                        onClick={showInput}
                    />
                </div>
                )
                :
                (<input className={cl.myInput} {...props}/>)
            }
        </div>
    );
};

export default MyInput;