import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Table } from 'react-bootstrap';
import "../components/componentStyles/StatisticTable.css"
const StatisticsTable = ({ domain, port }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isSelecting, setIsSelecting] = useState(false);

    useEffect(() => {
        let obj = {
            token: localStorage.getItem("token"),
            key: "getStatistics"
        }
        fetch(`https://${domain}/api/dataOperations`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                setData(data);
                console.log(data);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                setError(error.message);
            });

    }, []);

    if (error) {
        return <div>Error: {error}</div>;
    }

    function getTime(data) {
        let timeNow = Date.now();
        let difference = Number(data) - Number(timeNow);
        let minutes = 0;
        let hours = 0;
        minutes = Math.floor(difference / 1000 / 60);
        if (minutes < 0) {
            minutes = 0;
            hours = 0;
        } else {
            hours = Math.floor(minutes / 60);
            minutes = minutes - hours * 60;
        }
        hours = hours.toString();
        minutes = minutes.toString();

        if (hours.length < 2) {
            hours = "0" + hours;
        }
        if (minutes.length < 2) {
            minutes = "0" + minutes;
        }
        let resultString = `${hours}h ${minutes}m`

        return resultString;
    }

    function getCount(data) {
        let count = 0;
        // console.log(data);
        for (var key in data) {
            let middleData = Number(data[key].parsedData.newBalance);
            count += middleData;
        }
        return count.toFixed(4);
    }

    function isTimeZero(time) {
        return time === '00h 00m';
    }

    const handleMouseDown = (event, columnIndex) => {
        event.preventDefault();
        setIsSelecting(true);
        setSelectedColumns([columnIndex]);
    };

    const handleMouseMove = (event, columnIndex) => {
        if (isSelecting) {
            setSelectedColumns((prevSelectedColumns) => {
                const start = Math.min(prevSelectedColumns[0], columnIndex);
                const end = Math.max(prevSelectedColumns[0], columnIndex);
                return Array.from({ length: end - start + 1 }, (_, i) => start + i);
            });
        }
    };

    const handleMouseUp = () => {
        setIsSelecting(false);
        const selectedValues = data.map(item => Number(item.parsedData.newBalance).toFixed(4)).join('\n');
        navigator.clipboard.writeText(selectedValues);
    };

    return (
        <Container>
            <h2 className="tableTitle">Статистика по сборам</h2>
            <Table>
                <thead className="tableHeader">
                <tr>
                    <th className="text-center">ID</th>
                    <th className="text-center">Номер</th>
                    <th className="text-center" onMouseDown={(e) => handleMouseDown(e, 2)} onMouseMove={(e) => handleMouseMove(e, 2)} onMouseUp={handleMouseUp}>Баланс</th>
                    <th className="text-center">Время до клейма</th>
                </tr>
                </thead>
                <tbody className="tableBody">
                {data.map((item, index) => {
                    const time = getTime(item.parsedData.nextClaim);
                    const isZero = isTimeZero(time);

                    return (
                        <tr key={item.info.ID}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.info.number}</td>
                            <td className={`text-center ${selectedColumns.includes(2) ? 'selected' : ''}`}>{Number(item.parsedData.newBalance).toFixed(4)}</td>
                            <td className="text-center" style={{ color: isZero ? 'red' : 'inherit' }}>
                                {time}
                            </td>
                        </tr>
                    );
                })}
                </tbody>
                <tfoot>
                <tr>
                    <th></th>
                    <th className="text-end">Суммарно:</th>
                    <th className="text-center">{getCount(data)}</th>
                    <th></th>
                </tr>
                </tfoot>
            </Table>
            <div className="footerBtnDiv"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                      className="bi bi-arrow-bar-up" viewBox="0 0 16 16"
                      style={{cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}}
                      onClick={() => {
                          document.getElementById("headerElem").scrollIntoView();
                      }}
            >
                <path fill-rule="evenodd"
                      d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5m-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5"/>
            </svg></div>
        </Container>
    );
};

export default StatisticsTable;
