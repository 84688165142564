import React from 'react';

const EditFields = ({modal, modalData, setModalData, startSubmiting}) => {



    return (
        <div className="editFieldBody" id="editFieldBody">
            <div className="d-flex justify-content-center align-items-center flex-column">
                <h4>Редактирование данных пользователя</h4>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                     className="bi bi-arrow-bar-up" viewBox="0 0 16 16"
                     style={{cursor: "pointer"}}
                     onClick={() => {
                         document.getElementById("changeProfilesTitle").scrollIntoView();
                     }}
                >
                    <path fill-rule="evenodd"
                          d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5m-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5"/>
                </svg>
            </div>
            <form style={{margin: "20px 0px"}} className="row g-3 needs-validation" id="formToCheck" noValidate>
                <label className="form-label mt-4" id="labelMain">Основная информация пользователя</label>
                <div className="input-group mb-3 has-validation">
                    <span className="input-group-text" id="number-addon">number</span>
                    <input type="text" className="form-control" placeholder="7 911 111 11 11" id="number"
                           aria-describedby="number-addon"  required/>
                </div>
                <div className="input-group mb-3 has-validation">
                    <span className="input-group-text" id="number-addon">owner</span>
                    <input type="text" className="form-control" placeholder="userlogin.tg" id="owner"
                           aria-describedby="number-addon"  required/>
                </div>
                <label className="form-label mt-4">Информация localStorage</label>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="seeds-addon">seeds</span>
                    <input type="text" className="form-control" placeholder="Поле seeds в localStorage" id="seeds"
                           aria-describedby="seeds-addon"  required/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="deviceid-addon">deviceid</span>
                    <input type="text" className="form-control" placeholder="Поле seeds в localStorage"
                           id="deviceid" aria-describedby="deviceid-addon" required/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="loginKey-addon">loginKey</span>
                    <input type="text" className="form-control" placeholder="Идентификатор login в localStorage"
                           id="loginKey" aria-describedby="loginKey-addon" required/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="loginValue-addon">loginValue</span>
                    <input type="text" className="form-control" placeholder="Поле login в localStorage"
                           id="loginValue" aria-describedby="loginValue-addon" required/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="storageKey-addon">storageKey</span>
                    <input type="text" className="form-control" placeholder="Идентификатор storage в localStorage"
                           id="storageKey" aria-describedby="storageKey-addon" required/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="storageValue-addon">storageValue</span>
                    <input type="text" className="form-control" placeholder="Поле storage в localStorage"
                           id="storageValue" aria-describedby="storageValue-addon" required/>
                </div>
                <label className="form-label mt-4">Информация sessionStorage</label>
                <div className="input-group mb-3">
                        <span className="input-group-text"
                              id="telegram__initParams-addon3">__telegram__initParams</span>
                    <input type="text" className="form-control" placeholder="Поле в sessionStorage"
                           id="telegram__initParams" aria-describedby="telegram__initParams-addon3" required/>
                </div>
                <label className="form-label mt-4">Информация о прокси (не обязательно)</label>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="address-addon">Address</span>
                    <input type="text" className="form-control" placeholder="IP-адрес" id="address"
                           aria-describedby="address-addon"/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="port-addon">Port</span>
                    <input type="text" className="form-control" placeholder="Порт" id="port"
                           aria-describedby="port-addon"/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="login-addon">Login</span>
                    <input type="text" className="form-control" placeholder="Логин" id="login"
                           aria-describedby="login-addon"/>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="password-addon">Password</span>
                    <input type="text" className="form-control" placeholder="Пароль" id="password"
                           aria-describedby="password-addon"/>
                </div>
                <div className="px-2 w-auto d-flex justify-content-center">
                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal"
                            id="submitChanges" type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                startSubmiting();
                            }}
                    >
                        Сохранить изменения
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditFields;